#banner {
    background: url('../../../../Assets/Home/recurso_portada.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    padding-top: 80px;
    padding-bottom: 100px;
}

#banner p{
    width: 280px;
}

.bannerButton{
    background-color: #00AAFF;
    color: white;
    border: 1px solid #15C4A8;
    box-shadow: 0 0 30px #15C4A8;
    padding: 5px 50px 5px 50px;
}