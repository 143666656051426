.zigzag-green svg {
    margin-bottom: -20px;
}

#registros article:nth-child(2) {
    background-color: #F5F5F5;
    color: gray;
}

#registros article h3,
#registros article p {
    color: gray;
}

.instContainer {
    border-radius: 10px;
    background-color: white;
    height: 140px;
    width: 210px;
    margin: 0 15px 0 15px;
    box-shadow: 0 0 10px rgb(199, 198, 198);
    display: flex;
    align-items: center;
    justify-content: center;
}

.lastInst {
    padding: 30px;
    flex-direction: column;
}

.lastInst small {
    font-size: 8pt;
}

.reg_row{
    display: flex;
    justify-content: center;
}

.regContainer {
    height: 100px;
    width: 150px;
    margin: 10px 10px 20px 10px;
    padding: 10px;
    padding-top: 0;
    box-shadow: 0 0 10px rgb(199, 198, 198);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: #fff;
    flex-direction: column;
    position: relative;
}

.regContainer img {
    max-width: 95%;
    max-height: 95%;
}

.instContainer img {
    max-width: 100%;
    max-height: 100%;
}

.regContainer img {
    transition: 200ms all;
    filter: grayscale(100%);
}

.regContainer img:hover {
    filter: none;
}

#chile_container img {
    width: 70% !important;
}

#bolivia_container img{
    width: 55% !important;
}

.regContainer p {
    font-family: Montserrat-Medium, sans-serif !important;
    font-size: .7rem;
    margin-top: 5px;
    color: #00B0FF !important;
    position: absolute;
    bottom: 5px;
}