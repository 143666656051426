#mapa {
    background: url('../../../../Assets/Home/background_mapa_new7.svg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 650px;
    padding-top: 100px;
    padding-bottom: 80px;
}

#mapa .mapSections {
    margin-left: 130px;
}

.mapSections:nth-child(2) p {
    color: white;
    width: 280px;
}

.mapSections:nth-child(3) {
    margin-top: 80px;
}

.counterTitle {
    font-family: Montserrat-Bold, sans-serif;
    font-weight: bold;
    font-size: 45pt;
}

.counterText {
    margin-top: 20px;
    margin-bottom: 20px;
}

.counterText .first {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 15pt;
}

.counterText .second {
    font-size: 10pt;
    margin-top: 15px;
    display: block;
}

/* #logos {
    min-height: 120px;
    background-color: #292828;
}

#logos .logosIcons {
    width: 15%;
} */