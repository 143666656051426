#landing_container {
    max-width: 1800px;
    margin: 0 auto;
}

#landing_header {
    height: 90px;
    width: 100%;
    max-width: 1800px;
    position: fixed;
    background-color: #fff;
    z-index: 20;
}

#landing_logo {
    width: 15%;
}

#landing_banner {
    min-height: 600px;
    padding: 80px 80px 25px 80px;
    background-image: url('../../Assets/Home/recurso_portada.webp');
    background-size: cover;
    background-position: 70%;
}

#landing_banner h1 {
    color: #fff;
    font-family: Montserrat-Bold, sans-serif !important;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 3rem;
}

.banner_text {
    color: #000 !important;
    font-family: Montserrat-Bold, sans-serif !important;
    width: 80%;
    margin: 0 auto;
    font-size: .9rem;
    margin-bottom: 25px;
}

.banner_img {
    width: 60%;
    margin: 0 auto;
    display: block;
}

.landing_banner_zigzag {
    margin-bottom: 5px;
}

.landing_banner_zigzag svg {
    transform: scale(.4);
}

#landing_banner .img_container {
    padding-top: 50px;
}

#landing_estudios_container {
    background-color: #FAFAFA;
}

#landing_estudios {
    width: 100%;
    padding: 80px;
    padding-bottom: 40px;
}

.features_div {
    border: 2px solid #00B0FF;
    border-radius: 10px;
    padding: 10px 30px;
    max-width: 370px;
    margin: 0 auto;
}

.features_div p {
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: .8rem;
}

.features_div p:last-child{
    margin-bottom: 0;
}

.first_h2 {
    color: #00B0FF;
    font-family: Montserrat-Bold, sans-serif !important;
    margin-bottom: 30px;
    text-align: center;
}

.test_container p {
    margin-bottom: 0;
}

.test_card {
    width: 20%;
}

.test_card img {
    width: 43%;
}

.test_card p {
    font-family: Montserrat-Light, sans-serif !important;
    font-size: .6rem;
    margin-top: 5px;
    margin-bottom: -6px;
}

.test_card span {
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: .7rem;
    color: #707070;
}

.test_card small {
    font-size: .6rem;
    margin-top: -4px;
}

.second_h2 {
    width: 45%;
    color: #1583D8;
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: 1.7rem;
}

.video_modal {
    width: 90vw;
}

.video_img {
    transform: scale(1.3);
}

.video_h3 {
    font-family: Montserrat-Bold, sans-serif !important;
    color: #00AAFF;
    font-size: 1.2rem;
    cursor: pointer;
}

.landing_zigzag {
    background-color: #F5F5F5;
    text-align: center;
}

.landing_zigzag svg {
    margin-top: -30px;
}

#dark_container {
    background-color: #000;
}

#dark {
    height: 400px;
    padding-left: 170px;
    padding-right: 170px;
}

#dark p {
    font-family: Montserrat-Bold, sans-serif !important;
    color: #18DCB9;
    font-size: 1.5rem;
}

@media (max-width:1400px) {

    .banner_img {
        width: 100%;
    }

}

@media (max-width:1200px) {

    .banner_img {
        width: 90%;
    }

    #dark {
        padding-left: 100px;
        padding-right: 100px;
    }

}

@media (max-width:992px) {

    #landing_banner {
        padding: 150px 70px 65px 70px;
    }

    .banner_img {
        width: 100%;
    }

    .first_h2 {
        text-align: center;
        padding-left: 0;
        margin-bottom: 40px;
    }

    #dark {
        padding: 30px 50px 50px 50px;
        height: 450px;
    }

}

@media (max-width:768px) {

    #landing_logo {
        width: 30%;
    }

    #landing_banner {
        padding: 110px 10px 65px 10px;
        background-position: 65% 80%;
    }

    #landing_banner h1 {
        font-size: 3rem;
        padding: 20px;
    }

    .banner_text {
        font-size: 1.3rem;
    }

    #landing_banner .img_container {
        padding-top: 20px;
    }

    #landing_estudios {
        padding: 20px;
    }

}

@media (max-width:576px) {

    #landing_logo {
        width: 40%;
    }

    .test_container {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin: 0 auto;
    }

    .test_row {
        flex-direction: column;
        width: 50%;
    }

    .test_card {
        width: 100%;
        margin-bottom: 30px;
    }

    .test_card p {
        font-size: .8rem;
    }

    .test_card span {
        font-size: 1rem;
    }

    .second_h2 {
        width: 100%;
    }

}