#header {
  z-index: 20;
  width: 1800px;
}

.headerBackOnScroll {
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.hamburger {
  position: absolute;
  left: 30px;
  font-size: 2.5rem;
}

.headerLogos {
  width: 150px;
}

.sectionsLinks {
  transition: 300ms all;
}

.langButtons {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(#00B0FF, #fff);
  padding: 3px;
  border-radius: 5px;
  transition: 300ms all;
}

.langButtons:hover,
.currentLang {
  color: black;
  background: white;
}

footer {
  background: linear-gradient(white, #C8EEFF);
}

footer #container {
  margin-left: 100px;
  margin-right: 100px;
}

#footerLogo {
  width: 150px;
}

footer #aaip {
  width: 12rem;
}

.footerMedia {
  transition: 300ms all;
  margin: 0px 5px 0px 5px;
}

.footerMedia:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (min-width: 992px) {
  .hamburger {
    display: none;
  }

  .sectionsLinks:hover {
    color: #33EBBD;
  }
}

@media (max-width: 991px) {
  #header {
    background-color: transparent !important;
  }

  #desktopNav {
    display: none;
  }

  #hambMenu {
    background: linear-gradient(to bottom right,#33EBBD, #00AAFF);
  }

  .sectionsLinks {
    color: white;
    padding: 20px;
  }
}

@media (max-width: 420px) {
  #footerLogo {
    width: 100px;
  }
}

@media (max-width: 360px) {
  #footerLogo {
    width: 80px;
  }
}