#ourTeam,
#commercial_team,
#investorsAndAdvisors,
#awards {
  font-size: 3rem;
}

.imgTeam,
.imgCommercial {
  width: 9rem;
  transition: 300ms all;
}

.imgInvestors {
  width: 6rem;
  height: 6rem;
  transition: 300ms all;
}

.imgAdvisors,
.imgAwardsThree {
  width: 12rem;
}

#wayraLogo,
#puertoAsisLogo {
  width: 15rem;
}

.imgAwards {
  height: 4rem;
}

.imgAwardsTwo {
  height: 10rem;
}

#logoUSA,
#imagineCup,
#forbes {
  width: 10rem;
}

.imgTeam:hover,
.imgCommercial:hover,
.imgInvestors:hover {
  transform: scale(1.1);
}

#bannerThreeTeam {
  background-color: #6766eb;
}

#bannerFourTeam {
  background-color: #eff0f4;
}

@media (max-width: 500px) {
  #awards {
    font-size: 2rem;
  }
}
