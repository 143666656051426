#contacto {
    min-height: 500px;
    background-color: #6DF8E3;
}

#formContainer,
.form {
    border-radius: 10px;
}

#formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra el contenido verticalmente */
    background-color: white;
}

.form {
    width: 100%;
    height: 100%;
}

.form_header{
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: 1.5rem;
}