#quote {
    width: 35%;
    margin-top: 50px;
}

#slidersSection,
#cardsSection {
    padding: 50px 10px 80px 10px;
}

.evalCardContainer {
    width: 22%;
    height: auto;
}

.evaluationSlide,
.evalCard {
    background-color: white;
}

.evaluationSlide {
    margin: 20px;
    border-radius: 30px;
    padding: 20px;
    min-width: 286px;
    max-width: 25%;
    font-family: Montserrat-Regular, sans-serif;
    font-size: 11pt;
    height: 242px;
    line-height: 14pt;
}

.fixedSlide {
    width: 286px;
    height: 242px;
    margin-left: 10px;
}

.evalSlideEstudio {
    font-size: 13pt;
}

.evalSlideTitle {
    font-size: 15pt;
}

.evalSlideContent {
    margin-bottom: 10px;
    font-size: 25px;
}

.evalSlideIcon {
    width: 52px;
    height: 52px;
}

.evalCard {
    border-radius: 20px;
    position: relative;
    box-shadow: 10px 10px 60px rgba(88, 87, 87, .36);
    height: 90%;
}

.evalSlideDesc {
    font-size: 11px;
    line-height: 13px;
}

.evalCardVideo {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 10px;
}

.evalCardContent {
    font-size: 11px;
}

.evalImg {
    width: 50px;
}

.evalCardEstudio{
    font-family: Montserrat-Light, sans-serif;
    font-size: 10pt;
}

.evalCardName {
    font-family: Montserrat-Bold, sans-serif;
    font-size: 12pt;
    font-weight: bold;
}

.carousel-item {
    height: 430px;
    position: relative;
}

.evalCardDesc {
    font-size: 9pt;
}

.cardBtn,
.slideBtn {
    padding: 5px 30px 5px 30px;
    font-size: 10px;
    font-weight: normal;
    background-color: white;
    color: #00B0FF;
    box-shadow: 0 0 15px #94F4DC;
    border: 1px solid #94F4DC;
}

.cardBtn {
    position: absolute;
    bottom: 5%;
    left: 25%;
}

.slideBtn {
    font-size: 15px;
}

.arrow {
    width: 15%;
    position: absolute;
    top: 60px;
    transition: 300ms all;
}

.arrow:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.arrow-prev {
    left: -12%;
}

.arrow-next {
    right: -12%;
}