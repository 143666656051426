@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.otf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.otf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.otf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(../fonts/Montserrat-Light.otf);
}

@import url('./General/LearnMore.css');
@import url('./General/Typeform.css');
@import url('./General/Layout.css');
@import url('./Pages/Home/Home.css');
@import url('./Pages/Professionals.css');
@import url('./Pages/Team.css');
@import url('./Pages/Landing.css');

/* ESTILOS GENERALES */
* {
  font-family: 'Montserrat', sans-serif !important;
}

#testing-environment,
#development-environment {
  bottom: 0;
  left: 0;
  background-color: rgba(255, 0, 0, 0.5);
}

.list-style-none {
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  color: black;
}

a,
.links {
  color: #00B0FF;
  transition: 300ms all;
}

a:hover,
.links:hover {
  color: #33EBBD !important;
}

.currentLanguage {
  color: #33EBBD !important;
}

.flags {
  width: 2rem;
  box-shadow: 0 0 2px #000;
}

button {
  cursor: pointer;
  padding: 15px 60px 15px 60px;
  border-radius: 100px !important;
  color: white;
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

.strong-text {
  font-weight: bold;
}

/* ESTADOS DE TOASTS */
.success-toast {
  background-color: green !important;
  color: white !important;
}

.warning-toast {
  background-color: orange !important;
  color: white !important;
}

.error-toast {
  background-color: red !important;
  color: white !important;
}

/* LOGOS DE REDES */
.mediaLogos {
  width: 13%;
}


/* SECCIONES PRINCIPALES */
#teamMain,
#professionalsMain,
#ErrorMain {
  padding-top: 150px;
}

#teamMain {
  background-color: #aefee1;
}
