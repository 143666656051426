.typeformButton,
.typeformChat {
    all: unset;
    width: min-content !important;
}

.general {
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #fff;
    width: 50%;
    box-shadow: 0px 0px 20px #1DE9B6;
}

.green {
    background-color: #1DE9B6;
}

.blue {
    background-color: #00AAFF;
}

.lg {
    line-height: 50px;
    height: 50px;
    font-size: 20px;
    padding: 0 33px;
}

.sm {
    line-height: 15px;
    height: 40px;
    font-size: 15px;
    padding: 0 33px;
}