.zigzag-blue svg {
    width: 20%;
    margin-top: -2%;
    margin-left: 59%;
}

.toolsTitle {
    width: 50%;
}

#toolCardsContainer {
    border: 20px solid #E6EFF4;
    border-radius: 20px;
    background-color: #E6EFF4;
    width: 900px;
    height: 350px;
}

.shadowContainer {
    border-radius: 20px;
    box-shadow: 10px 10px 60px rgba(88, 87, 87, .36);
    height: 310px;
}

.toolsCard {
    background: linear-gradient(#1CE8B8, #01B2FC);
    padding: 30px;
    width: 300px;
    font-size: 12px;
}

.toolsCard:nth-child(1) {
    border-radius: 20px 0 0 20px;
}

.toolsCard:nth-child(4) {
    border-radius: 0 20px 20px 0;
}

.toolsCardImg {
    width: 40%;
}

.reportImg {
    width: 80%;
}

.reportTitle {
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: 34pt !important;
    color: #5A5A5A !important;
}

.toolsCardBtn,
.reportBtn {
    padding: 5px 35px 5px 35px;
    color: #01B2FC;
    font-size: 10px;
    background-color: white;
    border: 1px solid #1CE8B8;
    box-shadow: 0 0 10px #1CE8B8;
}

#toolsCardBtn0 {
    margin-top: 25px;
}

#toolsCardBtn1 {
    margin-top: 10px;
}

#toolsCardBtn2 {
    margin-top: 15px;
}

#toolsCardBtn3 {
    margin-top: 15px;
}

.reportBtn {
    margin-top: 30px;
    background-color: #1CE8B8;
    padding: 10px 80px 10px 80px;
    color: white;
}