.hardwareTitle {
    width: 35%;
}

.hardwareText {
    color: #00B0FF;
    border: 1px solid #00B0FF;
    padding: 25px 40px 25px 40px;
    border-radius: 20px;
    font-family: Montserrat-Regular, sans-serif !important;
}

.hardwareBtn {
    background-color: #16EFC8;
    border: 1px solid #16EFC8;
    box-shadow: 0 0 20px #16EFC8;
}

.boneheadDot,
.earphoneDot {
    color: #16EFC8;
    font-size: 18pt;
    margin-right: 20px;
}

.earphoneText h4,
.boneheadText h4 {
    font-size: 23pt;
}

.earphoneText p,
.boneheadText p {
    font-size: 13pt;
}

.earphoneText p {
    position: absolute;
    left: 4%;
    margin-bottom: 0;
}

.earphoneText p:nth-child(2) {
    top: 27%;
}

.earphoneText p:nth-child(3) {
    top: 40%;
}

.earphoneText p:nth-child(4) {
    top: 56%;
}

.earphoneText p:nth-child(5) {
    top: 71%;
}

.boneheadText h4 {
    width: max-content;
    position: absolute;
    top: 5%;
    left: 35%;
}

.boneheadText h4 .strong-text,
.earphoneText h4 .strong-text {
    font-family: Montserrat-Bold, sans-serif !important;
}

.boneheadText p {
    font-family: Montserrat-Light, sans-serif !important;
    position: absolute;
    text-align: end;
    margin-bottom: 0;
    font-size: 11pt;
    width: 95%;
}

.boneheadText p:nth-child(2) {
    top: 28%;
}

.boneheadText p:nth-child(3) {
    top: 69%;
}

.boneheadText p:nth-child(4) {
    top: 78.6%;
}

.boneheadImg,
.earphoneImg {
    width: 60%;
}