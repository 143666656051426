#caracteristicas {
    background: linear-gradient(black 65%, #27B491);
    padding-bottom: 80px;
}

#caracteristicas #product {
    width: 40%;
}

#caracteristicas .charCards {
    background-color: #191919;
    box-shadow: 0px 0px 5px gray;
    border-radius: 10px;
    text-align: center;
    width: 45%;
}

.charCards img {
    margin-top: -45px;
    margin-bottom: 45px;
    width: 35%;
}

#caracteristicas .charCards h4 {
    font-size: 1rem;
}

.charCards .charCardButtons {
    border: 1px solid #1CD2A5;
    padding: 5px 25px 5px 25px;
    font-size: 13px;
    transition: 200ms all;
}

.charCards .charCardButtons:hover {
    color: #1CD2A5;
}

.charCards p.text {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 9pt;
    padding: 15px;
}

#softwareCarrouselInner {
    display: flex;
    align-items: center;
}

.softwareCarrouselItems {
    display: block;
    margin: 0 auto;
    width: 40%;
    max-height: 100%;
}