.profImg {
    width: 12rem;
    transition: 300ms all;
}

.ambassImg {
    width: 8rem;
    transition: 300ms all;
}

.profImg:hover,
.ambassImg:hover{
    transform: scale(1.1);
}

.accordImg{
    width: 10rem;
}

.margin100Img{
    margin-top: 100px;
}


.accordWitherImg{
    width: 18rem;
}