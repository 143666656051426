#casos{
    background: linear-gradient(#BDF4F0, #B4F7E7);
}

.casosTitle {
    width: 30%;
}

#usxs {
    min-height: 500px;
    margin: 0 auto;
    border-radius: 20px;
    width: 80%;
    background-image: url('../../../../Assets/Home/background_usxs.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 30px;
    box-shadow: 10px 10px 60px rgba(88, 87, 87, .36);
}

.logoUSXS {
    width: 35%;
}

#videoUXS {
    border: 10px solid white;
    height: 300px;
    filter: grayscale(100%);
}

.usxsBtn {
    display: block;
    margin: 0 auto;
    background-color: #16EFC8;
    border: 1px solid #16EFC8;
    box-shadow: 0 0 20px #16EFC8;
    padding: 10px 40px 10px 40px;
}

.usxsh3,
.usxsBtn {
    margin-top: 30px;
}

.usxsh3 {
    font-family: Montserrat-Bold, sans-serif !important;
    font-size: 40pt !important;
}

#usxs p{
    font-family: Montserrat-Regular, sans-serif !important;
    font-size: 12pt !important;
}