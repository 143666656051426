@import url('./Sections/Banner.css');
@import url('./Sections/Mapa.css');
@import url('./Sections/Caracteristicas.css');
@import url('./Sections/Estudios.css');
@import url('./Sections/Herramientas.css');
@import url('./Sections/Hardware.css');
@import url('./Sections/Casos.css');
@import url('./Sections/Registros.css');
@import url('./Sections/Contacto.css');
@import url('./MediaQueries.css');

.miscUp,
.miscDown {
  background-color: transparent;
}

.miscUp {
  margin-top: -230px;
}

.miscDown {
  margin-top: -2.3%;
  margin-bottom: 20px;
}

#second_container {
  min-height: 2000px;
  background: url('../../../Assets/Home/background_estudios.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

#header,
main,
footer {
  max-width: 1800px;
  margin: 0 auto;
}
