.genericButton {
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #fff;
    width: 30%;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 0px 20px #1DE9B6;
    border: none;
    background-color: #1DE9B6;
    display: block;
    margin: 0px auto;
}

@media (max-width: 576px) {
    .genericButton {
        width: 60%;
    }
}