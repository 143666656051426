/* MEDIA QUERIES DE TODAS LAS SECCIONES DEL HOME */
@media (min-width: 1800px) {
    #header {
        left: 1%;
        right: 1%;
    }
}

@media (max-width: 1200px) {
    .evalCard {
        font-size: 10px;
    }
}

@media (min-width:992px) {
    #estudios #slidersSection {
        display: none;
    }
}

@media (max-width: 991px) {
    #banner {
        background-position: 65%;
    }

    #banner article {
        text-align: center !important;
    }

    #banner p {
        width: 70%;
        margin: 0 auto;
    }

    #mapa .miscUp {
        margin-top: -226px;
    }

    #mapa {
        background-size: auto;
        background-position: 50% 5%;
    }

    #mapa article:nth-child(1) {
        top: 10%;
        left: 10%;
    }

    #mapa article:nth-child(2) {
        top: 60%;
        left: 10%;
    }

    .softwareCarrouselItems {
        width: 50%;
    }

    #estudios #cardsSection {
        display: none !important;
    }

    .zigzag-blue svg {
        margin-top: -3%;
    }

    #toolCardsContainerParent {
        overflow: scroll;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width:860px) {
    .regContainer {
        scale: 85%;
    }
}

@media (min-width:769px) {

    .toolsCard:hover {
        background: #01B2FC;
        transform-origin: bottom;
        transform: scale(1.1);
        box-shadow: 10px 10px 60px rgba(88, 87, 87, .36);
        z-index: 1;
    }

    .toolsCard:nth-child(1):hover {
        border-radius: 20px 20px 0 20px;
    }

    .toolsCard:nth-child(2):hover,
    .toolsCard:nth-child(3):hover {
        border-radius: 20px 20px 0 0;
    }

    .toolsCard:nth-child(4):hover {
        border-radius: 20px 20px 20px 0;
    }
}

@media (max-width: 768px) {

    #mapa .mapSections {
        margin-left: 100px;
    }

    #caracteristicas #product {
        width: 50%;
    }

    .charCards img {
        margin-top: -30px;
        margin-bottom: 25px;
    }

    .softwareCarrouselItems {
        width: 70%;
    }

    #quote {
        width: 50%;
    }

    .zigzag-blue svg {
        margin-top: -4%;
    }

    .regContainer{
        margin: 0 auto;
        scale: 100%;
        margin-bottom: 30px;
    }

    .reg_row {
        flex-direction: column;
    }

    .toolsCard {
        width: 250px;
    }

    .hardwareTitle {
        width: 50%;
    }

    .earphoneText p{
        font-size: 7pt;
    }

    .casosTitle,
    .logoUSXS {
        width: 50%;
    }

    #usxs {
        width: 90%;
        background-position: 20%;
    }
}

@media (max-width: 576px) {

    #mapa {
        background-position: 50% 5%;
    }

    #mapa .mapSections {
        margin-left: 35px;
    }

    #logos .logosIcons {
        width: 25%;
    }

    .charCards .charCardButtons {
        font-size: 10px;
    }

    #caracteristicas #product {
        width: 70%;
    }

    .softwareCarrouselItems {
        width: 80%;
    }

    #quote {
        width: 70%;
    }

    .zigzag-blue svg {
        margin-top: -5%;
    }

    .toolsTitle {
        width: 100%;
    }

    .instContainer {
        height: 80px;
        min-width: 130px;
        margin: 0 10px 0 10px;
    }

    .earphoneText h4,
    .boneheadText h4 {
        font-size: 12pt;
    }

    .boneheadText h4 {
        top: 0;
        left: 30%;
    }

    .boneheadDot,
    .earphoneDot {
        font-size: 10pt;
        margin-right: 10px;
    }

    .boneheadText p {
        font-size: 8pt;
    }

    .boneheadText p:nth-child(3) {
        top: 67%;
    }

    .boneheadText p:nth-child(4) {
        width: 85%;
        left: 10%;
    }

    #videoUXS {
        width: 90%;
    }
}

@media (max-width: 460px) {
    .mapSections:nth-child(3) {
        margin-top: 40px;
    }

    .softwareCarrouselItems {
        width: 100%;
    }

    .earphoneText p{
        font-size: 5.7pt;
    }
}

@media (max-width: 420px) {
    #caracteristicas .charCards h4 {
        font-size: 0.7rem;
    }

    .charCards img {
        margin-top: -25px;
        margin-bottom: 20px;
    }

    .charCards .charCardButtons {
        font-size: 8px;
    }

    .zigzag-blue svg {
        margin-top: -6%;
    }
}

@media (max-width: 390px) {
    .carousel-item {
        height: 350px;
    }

    .earphoneText p{
        font-size: 5pt;
    }
}